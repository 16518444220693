@media (max-width: 1199px) {
  .category-item {
    &.large {
      height: 460px;
    }
  }
}
@media (max-width: 991px) {
  .category-item {
    margin-bottom: 12px;
    &.large {
      height: 560px;
      width: calc(50% - 6px);
    }
    &.small {
      width: calc(50% - 10px);
      height: 600px;
    }
  }
}
@media (max-width: 767px) {
  .category-item {
    &.large {
      height: 460px;
    }
    &-content {
      padding: 12px 20px;
    }
  }
}
@media (max-width: 680px) {
  .category-item {
    &.small,
    &.large {
      height: 380px;
    }
  }
}
@media (max-width: 520px) {
  .category-item {
    &.small,
    &.large {
      width: 100%;
      height: 460px;
    }
  }
}

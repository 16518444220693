.contact {
  & > .wrapper {
    padding: 60px 20px;
  }
  &-info {
    width: 370px;
    margin-right: 30px;
    ul {
      li {
        .icon {
          width: 46px;
          height: 46px;
          border: 2px solid $color-white;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          margin-right: 30px;
          img {
            display: block;
            width: 18px;
          }
        }
        span {
          display: block;
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 10px;
          text-transform: capitalize;
          color: $color-white;
        }
        a,
        p {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: $color-white;
        }
        a {
          transition: all 0.3s ease;
          &:hover {
            color: $color-wood;
          }
        }
        &:not(:last-of-type) {
          margin-bottom: 40px;
        }
      }
    }
  }
  &-image {
    width: calc(100% - 400px);
    img {
      display: block;
      width: 100%;
    }
    .slick-dots {
      text-align: center;
      padding-top: 16px;
      li {
        display: inline-block;
        margin: 0 5px;
        button {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $color-light;
          overflow: hidden;
          text-indent: 100%;
        }
        &.slick-active {
          button {
            background-color: $color-primary;
          }
        }
      }
    }
  }
  &-form {
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    padding: 30px 45px;
    h6 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 60px;
      color: $color-white;
    }
    label {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      span {
        display: block;
        font-size: 0.85rem;
        font-weight: 500;
        margin-bottom: 10px;
        color: $color-white;
      }
      input {
        width: 100%;
        height: 48px;
        border: 1px solid $color-primary;
        padding: 0 20px;
        border-radius: 5px;
      }
      textarea {
        width: 100%;
        height: 180px;
        border: 1px solid $color-primary;
        padding: 10px;
        resize: none;
        border-radius: 5px;
      }
      .error {
        color: $color-red;
        margin-top: 10px;
        text-transform: capitalize;
      }
    }
    .flex {
      label {
        width: calc(50% - 10px);
      }
    }
    button {
      min-width: 160px;
      padding: 10px;
      height: 48px;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 5px;
      background-color: $color-wood;
      color: $color-white;
    }
  }
  .map {
    width: 100%;
    height: 600px;
    .map-marker {
      width: 48px;
      margin-top: -40px;
      margin-left: -18px;
    }
  }
}

.shop {
  & > .wrapper {
    padding: 40px 20px 80px;
  }
  .product-not-found {
    width: 100%;
    text-align: center;
    img {
      width: 100px;
      margin-bottom: 30px;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.75rem;
    }
  }
}
.filter-section {
  border: 1px solid #f3f3f3;
  padding: 24px;
  margin-bottom: 40px;
  border-radius: 3px;
  .filter-title {
    color: $color-white;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 16px;
    text-transform: capitalize;
  }
  .field-item {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    span {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: capitalize;
      color: $color-white;
    }
    select {
      padding: 10px 40px 10px 20px;
      border-radius: 100px;
      border: 1px solid #8c8c8c;
      appearance: none;
      background-image: url('../../../assets/images/down-arrow-black.png');
      background-repeat: no-repeat;
      background-position: right 12px center;
      background-size: 15px;
      cursor: pointer;
      height: 44px;
    }
  }
  .price-range {
    input {
      padding: 11px 20px;
      border: 1px solid #8c8c8c;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 100px;
      margin-right: 10px;
      width: 120px;
      height: 44px;
      text-transform: capitalize;
      appearance: none;
    }
  }
  .search-field {
    margin-right: 0;
    .search-label {
      position: relative;
      button {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        img {
          display: block;
          width: 16px;
        }
      }
    }
    input {
      width: 220px;
      max-width: 100%;
      padding: 11px 20px;
      border: 1px solid #8c8c8c;
      border-radius: 100px;
      margin-right: 0;
    }
  }
  .filter-category {
    margin: 0 10px;
    b {
      display: block;
      font-size: 0.75rem;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .dropdown {
      position: relative;
      .selected {
        padding: 12px 16px;
        border: 1px solid;
        font-size: 0.85rem;
        font-weight: 400;
        cursor: pointer;
        min-width: 200px;
        white-space: nowrap;
      }
      &-content {
        padding: 30px;
        min-width: 240px;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 50px;
        z-index: 3;
        background-color: $color-white;
        //border-radius: 10px;
        label {
          display: flex;
          align-items: center;
          input {
            margin: 0;
            margin-right: 10px;
            width: 15px;
            height: 15px;
            // accent-color: red;
          }
          span {
            font-size: 0.85rem;
            //font-weight: 600;
            //white-space: nowrap;
          }
          &:not(:last-of-type) {
            margin-bottom: 14px;
          }
        }
      }
    }
  }
  .filter-sort {
    select {
      min-width: 200px;
      padding: 10px 16px;
      border: 1px solid;
      font-size: 0.85rem;
      font-weight: 400;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}

@media (max-width: 1300px) {
  header {
    .head {
      &-left {
        nav {
          .navbar {
            &-item {
              a {
                font-size: .75rem;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  header {
    .head {
      padding: 6px 0;
      &-left {
        .logo {
          margin-right: 20px;
        }
        nav {
          display: none;
          margin-right: 0;
          .navbar-item {
            .submenu-box {
              display: none !important;
            }
          }
          &.open {
            display: block;
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0;
            z-index: 4;
            left: 0;
            background: #000;
            .navbar {
              flex-direction: column;
              justify-content: center;
              height: 100%;
              &-item {
                a {
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
      &-right {
        .mobile-menu-burger {
          display: block;
          position: relative;
          z-index: 5;
        }
      }
    }
  }
  footer {
    .foo {
      &-top {
        .foo-menu {
          .foo-navbar {
            margin-left: 40px;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .wrapper {
    padding: 0 15px;
  }
  footer {
    .foo {
      &-top {
        .foo-info {
          width: calc(50% - 30px);
        }
        .foo-menu {
          width: calc(50% - 30px);
          .foo-navbar {
            display: none;
            margin-left: 0;
            &:first-child {
              display: block;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  html,
  body {
    font-size: 12px;
  }
  header  {
    .head {
      &-left {
        nav {
          &.open {
            .navbar-item {
              a {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
      &-right {
        .head-basket-btn {
          font-size: 1.25rem;
        }
        .language  {
          .selected-language {
            font-size: 1rem;
            svg {
              font-size: 0.85rem;
            }
          }
        }
      }
    }
  }
  .main {
    &-content {
      min-height: 70vh;
    }
    h1 {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  .subscribe {
    &-content {
      flex-direction: column;
      .subscribe-box {
        margin-bottom: 30px;
      }
    }
  }
}
@media (max-width: 520px) {
  .title {
    h2 {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }
  }
  header {
    .head {
      &-left {
        .logo {
          img {
            width: 80px;
          }
        }
      }
    }
  }
  .main {
    background-image: url('../../../assets/images/main-image-mobile.jpg') !important;
    &-content {
      min-height: 50vh;
      padding-bottom: 80px;
      h4 {
        font-size: 2.5rem;
        line-height: 3.5rem;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.75rem;
        line-height: 2.25rem;
      }
    }
  }
  .subscribe {
    &-content {
      .subscribe-box {
        flex-direction: column;
        span {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }
        input[type=email] {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }
        .subscribe-button {
          width: 100%;
        }
      }
    }
  }
  footer {
    .foo {
      &-top {
        flex-direction: column;
        align-items: center;
        text-align: center;
        .foo-info {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
          & > div {
            justify-content: center;
          }
        }
        .foo-menu {
          width: 100%;
          justify-content: center;
          border-top: 1px solid #333;
          padding-top: 30px;
          .foo-navbar {
            h4 {
              font-size: 1.5rem;
            }
          }
        }
      }
      &-bottom {
        flex-direction: column;
        p {
          margin-bottom: 20px;
        }
      }
    }
  }

  .pagination {
    .page-item {
      .page-link {
        width: 24px;
        height: 24px;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 991px) {
  .blog {
    .blog-item {
      width: calc(50% - 12px);
      margin-right: 24px;
      &:nth-child(3n) {
        margin-right: 24px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 620px) {
  .blog {
    .blog-item {
      width: 100%;
      margin-right: 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .blog-inner {
      .title {
        font-size: 18px;
        line-height: 26px;
      }
      p {
        font-size: 13px;
        line-height: 21px;
      }
      h2 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}

:root {
  --toastify-color-dark: #16212c;
  --toastify-color-info: #3333ad;
  --toastify-color-success: #258925;
  --toastify-color-warning: #e0c041;
  --toastify-color-error: #c41e3a;
  --toastify-color-progress-dark: #16212c;
  --toastify-color-progress-info: #3333ad;
  --toastify-color-progress-success: #258925;
  --toastify-color-progress-warning: #e0c041;
  --toastify-color-progress-error: #c41e3a;
}

.wrapper {
  max-width: 1360px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}

main {
  &.hidden {
    overflow: hidden;
    height: 100vh;
  }
}

header {
  background-color: rgba($color-dark, 0.7);
  color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  .head {
    &-left {
      .logo {
        width: 80px;
        margin-right: 20px;
        position: relative;
        img {
          display: block;
          width: 100%;
        }
      }
      nav {
        margin-right: 30px;
        .navbar {
          &-item {
            position: relative;
            margin: 0 5px;
            a,
            .submenu-item {
              display: block;
              padding: 28px 5px;
              font-size: 0.85rem;
              color: $color-white;
              font-weight: 600;
              text-transform: capitalize;
              transition: all 0.3s ease;
              &:hover {
                color: $color-wood;
              }
            }
            .submenu-box {
              display: none;
              position: absolute;
              top: 69px;
              left: 0;
              background: rgba($color-dark, 1);
              padding: 10px;
              z-index: 2;
              img {
                // display: none;
                height: 220px;
              }
            }
            .submenu {
              li {
                a {
                  display: block;
                  white-space: nowrap;
                  padding: 15px 20px;
                  cursor: pointer;
                }
                &:hover {

                }
              }
              /*&:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 10px solid rgba($color-primary, .7);
                position: absolute;
                top: -10px;
                left: 28px;
              }*/
            }
            &:hover {
              .submenu-box {
                display: flex;
              }
            }
          }
        }
      }
    }
    &-right {
      .head-basket {
        margin-right: 24px;
        &-btn {
          color: $color-white;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          .count {
            position: absolute;
            font-size: 0.7rem;
            font-weight: 500;
            background: $color-wood;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            top: -10px;
            right: -10px;
          }
          img {
            display: block;
            width: 20px;
          }
          &:hover {
            color: $color-wood;
            .count {
              background-color: #fff;
            }
          }
        }
      }
      .language {
        display: flex;
        align-content: center;
        position: relative;
        margin-right: 16px;
        img {
          display: block;
          width: 16px;
          margin-right: 5px;
        }
        .selected-language {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 0.875rem;
          img {
            margin-left: 5px;
            font-size: 0.75rem;
          }
        }
        &-list {
          position: absolute;
          z-index: 2;
          top: 35px;
          right: -22px;
          background: rgba($color-primary, 1);
          padding: 12px 16px;
          li {
            font-size: 0.85rem;
            font-weight: bold;
            cursor: pointer;
            display: flex;
            align-items: center;
            img {
              display: block;
              margin-right: 10px;
              width: 20px;
            }
            &:not(:last-of-type) {
              margin-bottom: 8px;
            }
          }
          &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid rgba($color-primary, 0.5);
            position: absolute;
            top: -10px;
            right: 20px;
          }
        }
        &.open {
          .selected-language {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
      .head-search-button {
        img {
          display: block;
          width: 20px;
        }
      }
      .mobile-menu-burger {
        display: none;
        margin-left: 16px;
        button {
          font-size: 1.5rem;
          color: $color-white;
          img {
            display: block;
            width: 24px;
          }
        }
      }
    }
  }
  .head-search {
    border-top: 1px solid rgba($color-white, 0.3);
    z-index: 3;
    .wrapper {
      padding-top: 12px;
      padding-bottom: 12px;
      & > div {
        position: relative;
        input {
          height: 42px;
          width: 100%;
          border: none;
          font-size: 0.875rem;
          padding: 0 12px;
          text-transform: capitalize;
        }
        button,
        a {
          display: flex;
          align-items: center;
          position: absolute;
          right: 1px;
          top: 1px;
          height: calc(100% - 2px);
          background-color: #222222;
          color: $color-white;
          font-size: 0.875rem;
          padding: 0 24px;
          text-transform: capitalize;
        }
      }
    }
  }
}

.subscribe {
  padding: 30px 0;
  background: $color-dark;
  color: $color-white;
  border-bottom: 1px solid #333;
  &-content {
    .subscribe-box {
      span {
        font-weight: 500;
        font-size: 1rem;
      }
      input[type="email"] {
        min-width: 240px;
        height: 40px;
        background-color: $color-white;
        border: none;
        padding: 0 15px;
        margin-right: 20px;
      }
      .subscribe-button {
        min-width: 120px;
        padding: 10px;
        height: 40px;
        color: $color-white;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 1rem;
        background-color: $color-wood;
      }
    }
    .social-box {
      span {
        font-weight: 500;
        font-size: 1rem;
      }
      ul {
        li {
          a {
            color: $color-wood;
            font-size: 1.25rem;
          }
          &:not(:last-of-type) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

footer {
  .foo {
    background: $color-dark;
    padding: 60px 0 40px;
    color: $color-white;
    &-top {
      padding-bottom: 40px;
      .foo-info {
        margin-right: 40px;
        .foo-logo {
          margin-bottom: 30px;
          width: 145px;
          img {
            display: block;
            width: 100%;
          }
        }
        .foo-address,
        .foo-email,
        .foo-phone {
          display: flex;
          font-weight: 500;
          a {
            color: $color-white;
            font-weight: 500;
            transition: all 0.3s ease;
            &:hover {
              color: $color-wood;
            }
          }
          img {
            display: block;
            width: 18px;
            margin-right: 10px;
          }
          span {
            margin-left: 15px;
            a {
              display: inline-block;
              transition: all 0.3s ease;
              img {
                display: block;
                width: 24px;
              }
              &:last-child {
                margin-right: 0;
              }
              &:hover {
                opacity: 0.7;
              }
            }
          }
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
        .social-box {
          padding-top: 20px;
          span {
            font-weight: 500;
            font-size: 1rem;
            text-transform: capitalize;
          }
          ul {
            li {
              a {
                color: $color-white;
                font-size: 1.25rem;
                transition: all 0.3s ease;
                img {
                  display: block;
                  width: 18px;
                }
                &:hover {
                  opacity: 0.7;
                }
              }
              &:not(:last-of-type) {
                margin-right: 16px;
              }
            }
          }
        }
      }
      .foo-navbar {
        margin-left: 60px;
        h4 {
          margin-bottom: 20px;
          font-size: 1.15rem;
          color: $color-white;
          font-weight: 500;
          text-transform: capitalize;
        }
        ul {
          li {
            line-height: 2rem;
            a {
              font-size: 1rem;
              color: $color-white;
              transition: all 0.3s ease;
              text-transform: capitalize;
              text-align: left;
              &:hover {
                color: $color-wood;
              }
            }
          }
        }
      }
    }
    &-bottom {
      padding-top: 40px;
      border-top: 1px solid #333;
      .payment-methods {
        li {
          & > img {
            height: 42px;
          }
          span {
            display: block;
            padding: 4px 10px;
            background-color: $color-white;
            border-radius: 2px;
            img {
              height: 18px;
            }
          }
        }
      }
    }
  }
}
.pagination {
  display: flex;
  align-items: center;
  padding-top: 30px;
  .page-item {
    margin: 0 3px;
    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 600;
      color: $color-white;
      width: 32px;
      height: 32px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      cursor: pointer;
      img {
        display: block;
        width: 20px;
      }
    }
    &.prev,
    &.next {
      .page-link {
        background-color: transparent !important;
      }
    }
    &.breakDots {
      .page-link {
        background-color: transparent !important;
        color: #000;
      }
    }
    &.disabled {
      opacity: 0.6;
      cursor: default;
    }
    &.active {
      .page-link {
        background-color: $color-wood !important;
        color: $color-white;
      }
    }
  }
}

.Toastify__toast-body {
  font-family: $color-primary;
  font-size: 16px;
  line-height: 22px;
}
.Toastify__toast-body > div {
  font-weight: 500;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #151515;
  &-content {
    width: 75px;
    height: 75px;
    overflow: hidden;
  }
}
.search-btn {
  cursor: pointer;
}
#ec7uO5GWwgt2_to {
  animation: ec7uO5GWwgt2_to__to 3000ms linear infinite normal forwards;
}
@keyframes ec7uO5GWwgt2_to__to {
  0% {
    transform: translate(-33.526761px, 58.52747px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10% {
    transform: translate(54.473239px, 58.52747px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(54.473239px, 58.52747px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    transform: translate(-33.526761px, 58.52747px);
  }
  100% {
    transform: translate(-33.526761px, 58.52747px);
  }
}
#ec7uO5GWwgt3_to {
  animation: ec7uO5GWwgt3_to__to 3000ms linear infinite normal forwards;
}
@keyframes ec7uO5GWwgt3_to__to {
  0% {
    transform: translate(51.963076px, 219.242784px);
  }
  40% {
    transform: translate(51.963076px, 219.242784px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(51.963076px, 139.242784px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(51.963076px, 139.242784px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    transform: translate(51.963076px, 219.242784px);
  }
  100% {
    transform: translate(51.963076px, 219.242784px);
  }
}
#ec7uO5GWwgt4_to {
  animation: ec7uO5GWwgt4_to__to 3000ms linear infinite normal forwards;
}
@keyframes ec7uO5GWwgt4_to__to {
  0% {
    transform: translate(123.429541px, -69.93735px);
  }
  20% {
    transform: translate(123.429541px, -69.93735px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  30% {
    transform: translate(123.429541px, 57.06265px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(123.429541px, 57.06265px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    transform: translate(123.429541px, -69.93735px);
  }
  100% {
    transform: translate(123.429541px, -69.93735px);
  }
}
#ec7uO5GWwgt5_to {
  animation: ec7uO5GWwgt5_to__to 3000ms linear infinite normal forwards;
}
@keyframes ec7uO5GWwgt5_to__to {
  0% {
    transform: translate(224.5567px, 153.34459px);
  }
  60% {
    transform: translate(224.5567px, 153.34459px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    transform: translate(127.5567px, 153.34459px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(127.5567px, 153.34459px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    transform: translate(224.5567px, 153.34459px);
  }
  100% {
    transform: translate(224.5567px, 153.34459px);
  }
}

.collapse-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background: #222;
    border-radius: 5px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .collapse-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:16px 24px;
        border-bottom: 1px solid #dee2e6 !important;
        cursor: pointer;

        h6 {
            font-size: 1.15rem;
        }

        .arrow {
            width: 16px;
            font-size: 1.15rem;
            transition: transform 0.3s ease-in-out;
        }    
    }

    .collapse-content {
        width: 100%;
        height:auto;
        max-height: 0;
        overflow: hidden;

        p {
            padding:32px 24px;
        }
    }

    &.active {
        .arrow {
            transform: rotate(180deg);
        }

        .collapse-content {
            max-height: 100%;
        }
    }
}
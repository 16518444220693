@media (max-width: 991px) {
  .filter-section {
    display: block;
    .filter-left {
      display: block;
      .filter-title {
        display: none;
      }
      .field-item {
        margin-right: 0;
        margin-bottom: 20px;
      }
      .filter-category {
        margin: 0;
      }
      .price-range {
        input {
          width: calc(50% - 5px);
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .search-field {
      input {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .filter-section {
    border: none;
    padding: 0;
  }
}

.category-item {
  color: $color-white;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 12px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s ease;
  }
  &-content {
    position: absolute;
    z-index: 2;
    padding: 24px 24px 12px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .category-name {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  .category-description {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  a {
    display: inline-block;
    background-color: transparent;
    border: 2px solid $color-white;
    color: $color-white;
    font-weight: 500;
    padding: 15px;
    max-width: 240px;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    text-transform: capitalize;
    &:hover {
      background-color: $color-white;
      color: $color-primary;
    }
  }
  &.large {
    width: calc(33% - 5px);
    height: 560px;
  }
  &.small {
    width: calc(25% - 15px);
    height: 400px;
  }
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    opacity: 0;
    transition: all .5s ease;
  }
  &:hover {
    & > img {
      transform: scale(1.15);
    }
    &:before {
      opacity: .45;
    }
  }
}

.main {
  background: url("https://via.placeholder.com/1000x1000/a24444/a24444/?text=.")
    no-repeat center center;
  background-size: cover;
  color: $color-white;
  position: relative;
  &-content {
    min-height: 100vh;
    position: relative;
    z-index: 2;
    padding-top: 80px;
    padding-bottom: 60px;
  }
  h1 {
    font-size: 4.2rem;
    line-height: 5.6rem;
    margin-bottom: 15px;
    color: #fff;
  }
  p {
    font-size: 1.75rem;
    line-height: 2.5rem;
    color: #fff;
  }
  h4 {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
    span {
      font-weight: bold;
    }
    b {
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .owl-carousel {
    .owl-item {
      img {
        display: block;
        width: 120%;
        height: 100vh;
        object-fit: cover;
      }
    }
  }
  .owl-nav {
    position: absolute;
    right: 50px;
    bottom: 50px;
    left: 50px;
    z-index: 5;
    height: 50px;
    .owl-prev,
    .owl-next {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50px;
      height: 50px;
      font-size: 0;
      background-image: url("../../../assets/images/icons/left-arrow-white.png");
      background-repeat: no-repeat;
      background-position: 50%;
      border: 1px solid hsla(0, 0%, 100%, 0.3);
      border-radius: 50%;
      background-size: 24px;
    }
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
      transform: rotate(180deg);
    }
  }
  .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: absolute;
    right: 100px;
    bottom: 50px;
    left: 100px;
    z-index: 5;
    counter-reset: dots;
    .owl-dot {
      width: 40px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      &:before {
        counter-increment: dots;
        content: counter(dots);
        margin: 0 auto;
      }
      &.active {
        &:before {
          display: block;
          width: 15px;
          height: 15px;
          background-color: #fff;
          border-radius: 50%;
        }
      }
    }
  }
  /*&:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    opacity: 0.75;
  }*/
}
.discounted-banner {
  &-content {
    display: flex;
    align-items: center;
    background: url("https://via.placeholder.com/1000/a24444/a24444/?text=.")
      no-repeat center center;
    background-size: cover;
    position: relative;
    min-height: 240px;
    padding: 48px 36px;
  }
}

.chat-block {
  position: fixed;
  right: 20px;
  bottom: 0;
  z-index: 999;
  .cursor {
    cursor: pointer;
  }

  .chat-block-text {
    border-radius: 12px 12px 0 0;
    font-size: 16px;
    color: #fff;
    background-color: $color-wood;
    padding: 16px 48px;
    display: inline-block;
    cursor: pointer;
  }
  .chat-icons {
    position: fixed;
    bottom: 60px;
    border-radius: 12px;
    overflow: hidden;
    right: 20px;

    & > div {
      display: flex;
      align-items: center;
      padding: 12px;
      width: 196px;
      background-color: $color-wood;

      & > img {
        display: block;
        width: 20px;
        margin-right: 12px;
      }
      & > span {
        font-size: 16px;
        color: #fff;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
}

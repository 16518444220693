 @media (max-width: 991px) {
    .error-404 {
        padding-top: 0;

        .content {
            flex-direction: column;

            .left-side {
                margin:0;
                padding: 0;
                text-align: center;

                img {
                    width: 90%;
                }
            }
            
            .right-side {
                margin-top: 40px;

                .title {
                    font-size: 16px;
                }

                .description {
                    font-size: 12px;
                }
            }
         }
    }
  }

@font-face {
  font-family: 'Montserrat arm';
  src: url('../fonts/Montserratarm-Medium.eot');
  src: url('../fonts/Montserratarm-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserratarm-Medium.woff2') format('woff2'),
  url('../fonts/Montserratarm-Medium.woff') format('woff'),
  url('../fonts/Montserratarm-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat arm';
  src: url('../fonts/Montserratarm-Light.eot');
  src: url('../fonts/Montserratarm-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserratarm-Light.woff2') format('woff2'),
  url('../fonts/Montserratarm-Light.woff') format('woff'),
  url('../fonts/Montserratarm-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat arm';
  src: url('../fonts/Montserratarm-Bold.eot');
  src: url('../fonts/Montserratarm-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserratarm-Bold.woff2') format('woff2'),
  url('../fonts/Montserratarm-Bold.woff') format('woff'),
  url('../fonts/Montserratarm-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat arm';
  src: url('../fonts/Montserratarm-SemiBold.eot');
  src: url('../fonts/Montserratarm-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserratarm-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserratarm-SemiBold.woff') format('woff'),
  url('../fonts/Montserratarm-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat arm';
  src: url('../fonts/Montserratarm-Regular.eot');
  src: url('../fonts/Montserratarm-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserratarm-Regular.woff2') format('woff2'),
  url('../fonts/Montserratarm-Regular.woff') format('woff'),
  url('../fonts/Montserratarm-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.eot');
  src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Light.woff2') format('woff2'),
  url('../fonts/Montserrat-Light.woff') format('woff'),
  url('../fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.eot');
  src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Medium.woff2') format('woff2'),
  url('../fonts/Montserrat-Medium.woff') format('woff'),
  url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.eot');
  src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.eot');
  src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


$font-primary: 'Montserrat';
$font-am: 'Montserrat arm';


// Colors

$color-white: #fff !default;
$color-primary: #16212c !default;
$color-light: #848484 !default;
$color-dark: #222 !default;
$color-red: #ED6B5B !default;
$color-wood: #924936 !default;
$color-green: #165954 !default;

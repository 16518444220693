.error-404 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 1360px;
    padding-top: 68px;
    margin: 0 auto;
  
    .content {
        padding : 80px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
     
        .left-side { 
            margin-right: 40px;
        }

        .right-side {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .title {
                font-size: 2.5rem;
                color: #fff;
                margin:0;
                font-weight: bold;
            }
        
            .description {
                font-size: 1.75rem;
                color: #fff;
                margin:20px 0;
            }

            .btn {
                display: block;
                max-width: 280px;
                width: 100%;
                padding: 10px;
                height: 60px;
                color: #fff;
                font-size: 1rem;
                font-weight: 500;
                border-radius: 5px;
                background-color: #924936;
                margin-top: 20px;
            }
        }
    }
}
.cart {
  &-content {
    padding: 60px 0;
    .cart-table {
      width: 100%;
      border: 1px solid rgba($color-light, .1);
      thead {
        tr {
          th {
            background-color: rgba($color-light, .1);
            padding: 20px;
            font-size: 1rem;
            font-weight: 500;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid rgba($color-light, .1);
          td {
            padding: 20px;
            vertical-align: middle;
            text-align: left;
            font-size: 1rem;
            line-height: 1.25rem;
            .cart-product-name {
              cursor: pointer;
              img {
                display: block;
                width: 100%;
              }
            }
            &.td-title {
              strong {
                display: block;
                font-size: .85em;
                margin-top: 5px;
              }
              .accessories-button {
                cursor: pointer;
                font-weight: 500;
                text-transform: capitalize;
                text-decoration: underline;
                margin-top: 8px;
              }
            }
            .quantity {
              display: flex;
              align-items: center;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 46px;
                font-size: 24px;
                cursor: pointer;
                color: $color-primary;
                background-color: #f3f3f3;
                user-select: none;
              }
              .quantity-number {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 46px;
                font-size: 1rem;
                font-weight: 400;
                border: 1px solid #f3f3f3;
                border-right: 1px solid #f3f3f3;
              }
            }
            &:last-child {
              text-align: center;
              button {
                img {
                  display: block;
                  width: 20px;
                }
              }
            }
          }
          &.tr-total {
            text-align: left;
            .buy-button {
              background-color: $color-green;
              color: $color-white;
              font-size: 1rem;
              border-radius: 5px;
              padding: 12px 30px;
            }
            span {
              font-weight: bold;
              .old-price {
                opacity: .5;
                text-decoration: line-through;
              }
            }
            i {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
    .cart-empty {
      border: 1px solid rgba(132, 132, 132, 0.1);
      padding: 40px 20px;
      text-align: center;
      img {
        display: block;
        width: 120px;
        max-width: 30%;
        margin: 0 auto 30px;
      }
      h4 {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}

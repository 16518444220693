@media (max-width: 620px) {
  .popup {
    &-body {
      .payment-form-inner {
        display: block;
        label {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}
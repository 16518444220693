.about {
  & > .wrapper {
    padding: 60px 20px 80px;
    max-width: 1000px;
  }
  &-box {
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: $color-white;
      span {
        background: transparent !important;
        color: $color-white !important;
      }
    }
  }
  &-slider {
    padding: 100px 0 0;
    .slick-slider {
      .slick-slide {
        & > div {
          & > div {
            overflow: hidden;
          }
        }
        img {
          display: block;
          width: calc(100% - 20px);
          opacity: .8;
          margin: 10px;
          transition: all .3s ease;
        }
        &.slick-center {
          img {
            -ms-transform: scale(1.08);
            transform: scale(1.08);
            opacity: 1;
          }
        }
      }
      .slick-arrow {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        text-indent: 100%;
        background-color: $color-wood;
        position: absolute;
        z-index: 1;
        top: 50%;
        margin-top: -30px;
        &:before {
          content: '';
          border: solid $color-white;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 4px;
          position: absolute;
          left: 50%;
          margin-left: -5px;
          top: 50%;
          margin-top: -5px;
        }
        &.slick-prev {
          left: 30px;
          &:before {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }
        }
        &.slick-next {
          right: 30px;
          &:before {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

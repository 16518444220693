* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  background: transparent;
  font-weight: 300;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ol,
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
:focus {
  outline: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
html,
body {
  font-weight: 400;
  font-size: 16px;
}
body {
  // font-family: $font-primary;
  line-height: 1;
  background: #151515;
  color: $color-white;
}
img {
  border: 0;
}
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  font-size: 100%;
  background: transparent;
}
.clear {
  clear: both;
}
a:active,
a:focus,
a:hover {
  border: none;
  outline: none;
  text-decoration: none;
}
input:active,
input:focus {
  outline: none;
}
textarea,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
hr {
  border: none;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: $font-primary;
}
input[type='radio'] {
  -webkit-appearance: radio;
}

input::-webkit-input-placeholder {
  color: #000;
}
input::-moz-placeholder {
  color: #000;
}
input:-ms-input-placeholder {
  color: #000;
}
input:-moz-placeholder {
  color: #000;
}

textarea::-webkit-input-placeholder {
  color: #c7c7c7;
}
textarea::-moz-placeholder {
  color: #c7c7c7;
}
textarea:-ms-input-placeholder {
  color: #c7c7c7;
}
textarea:-moz-placeholder {
  color: #c7c7c7;
}

input:focus::-webkit-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
input:focus:-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
input:focus::-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
input:focus:-ms-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus::-webkit-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus:-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus::-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus:-ms-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}

.flex {
  display: flex;
  &-jc-end {
    justify-content: flex-end;
  }
  &-jc-between {
    justify-content: space-between;
  }
  &-jc-center {
    justify-content: center;
  }
  &-ai-center {
    align-items: center;
  }
  &-ai-end {
    align-items: flex-end;
  }
  &-wrap {
    flex-wrap: wrap;
  }
}

.product {
  padding-top: 68px;
  & > .wrapper {
    padding: 80px 20px;
  }
  &-content {
    margin-bottom: 80px;
    .product-gallery {
      width: calc(60% - 40px);
      margin-right: 40px;
      .slick-for {
        .slick-arrow {
          display: none !important;
        }
      }
      .slick-nav {
        .slick-arrow {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;
          text-indent: 100%;
          background-color: rgba($color-wood, 0.8);
          position: absolute;
          z-index: 1;
          top: 50%;
          margin-top: -18px;
          transition: all 0.3s ease;
          &:before {
            content: "";
            border: solid $color-white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -5px;
          }
          &.slick-prev {
            left: 30px;
            &:before {
              transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              margin-left: -3px;
            }
          }
          &.slick-next {
            right: 30px;
            &:before {
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              margin-left: -7px;
            }
          }
          &:hover {
            background-color: $color-wood;
          }
        }
      }
      .slick-slide {
        padding: 0 10px;
        img {
          width: 100%;
        }
      }
      //.slick-for {
      //  .slick-slide {
      //    height: 490px;
      //    & > div {
      //      height: 100%;
      //      & > div {
      //        height: 100%;
      //      }
      //    }
      //    img {
      //      height: 100%;
      //      object-fit: contain;
      //    }
      //  }
      //}
      .slick-nav {
        .slick-list {
          .slick-slide {
            padding: 10px;
            cursor: pointer;
            opacity: 0.6;
            overflow: hidden;
            // max-height: 150px;
            &.slick-current {
              opacity: 1;
            }
          }
        }
      }
    }
    .product-info {
      width: 40%;
      .code {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .name {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        margin-bottom: 20px;
      }
      ul {
        margin-bottom: 30px;
        li {
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
          line-height: 2rem;
          width: 100%;
          max-width: 100%;
          position: relative;
          span {
            background-color: #151515;
            padding-right: 10px;
            text-transform: capitalize;
          }
          strong {
            font-weight: 500;
            background-color: #151515;
            padding-left: 10px;
          }
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            border-top: 1px dashed;
            z-index: -1;
          }
        }
      }
      .description {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
        margin-bottom: 30px;
      }
      .count,
      .price {
        .field-name {
          font-size: 0.85rem;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
      .count {
        margin-right: 60px;
        .quantity {
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 46px;
            font-size: 24px;
            cursor: pointer;
            color: $color-primary;
            background-color: #f3f3f3;
            user-select: none;
          }
          .quantity-number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 46px;
            font-size: 1rem;
            font-weight: 400;
            border: 1px solid #f3f3f3;
            border-right: 1px solid #f3f3f3;
          }
        }
      }
      .price {
        span {
          display: flex;
          flex-direction: column;
          padding: 15px 30px;
          background-color: #f3f3f3;
          color: $color-primary;
          font-weight: 400;
          b {
            &.old-price {
              text-decoration: line-through;
              opacity: 0.5;
            }
            &:not(:last-of-type) {
              margin-bottom: 8px;
            }
          }
        }
      }
      .add-basket-button,
      .added-basket-button {
        display: block;
        max-width: 280px;
        width: 100%;
        padding: 10px;
        height: 60px;
        color: $color-white;
        font-size: 1rem;
        font-weight: 500;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        background-color: $color-wood;
      }
      .added-basket-button {
        background-color: $color-green;
      }
      .text-info {
        display: block;
        color: $color-white;
        font-weight: 400;
        margin-top: 32px;
        font-size: 14px;
        font-style: italic;
      }
    }
  }
}

.blog {
  & > .wrapper {
    padding: 40px 20px;
  }
  .blog-item {
    width: calc(33.3% - 21.6px);
    margin-right: 32px;
    margin-bottom: 32px;
    cursor: pointer;
    .image {
      max-height: 280px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .3s ease;
      }
    }
    .info {
      padding: 16px;
      h6 {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 16px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:hover {
      .image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .blog-inner {
    img {
      display: block;
      width: 100%;
      max-width: 600px;
      margin-bottom: 32px;
    }
    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    h2 {
      font-size: 32px;
      line-height: 48px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    a {
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.product-item {
  width: calc(25% - 9px);
  margin-right: 12px;
  .product-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 40px 20px;
    background-color: #f3f3f3;
    height: 270px;
    overflow: hidden;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      transition: all .3s ease;
    }
    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      // padding: 10px 20px;
      color: $color-white;
      //background-color: $color-wood;
      background-image: url('../../../assets/images/discount.svg');
      width: 60px;
      background-size: contain;
      height: 59px;
      font-size: 0.85rem;
      font-weight: bold;
    }
    .cart-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transform: translateY(100%);
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 16px;
      background-color: rgba($color-wood, .8);
      transition: all .3s ease;
      span {
        font-size: 14px;
        font-weight: 500;
        color: $color-white;
      }
      img {
        display: block;
        width: 20px;
      }
      &:hover {
        background-color: rgba($color-wood, 1);
      }
    }
    &:hover {
      a {
        img {
          transform: scale(1.1);
        }
      }
      .cart-button {
        transform: translateY(0);
      }
    }
  }
  .product-name {
    display: block;
    color: $color-white;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 12px 20px 0 10px;
    font-weight: 500;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      color: $color-wood;
    }
  }
  .product-info {
    li {
      color: $color-white;
      font-size: 0.85rem;
      line-height: 1.5rem;
    }
  }
  .product-prices {
    text-align: right;
    .product-price {
      color: $color-white;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
      white-space: nowrap;
    }
    .product-oldPrice {
      color: $color-white;
      font-size: 1rem;
      line-height: 1.5rem;
      text-decoration: line-through;
      opacity: 0.7;
      white-space: nowrap;
    }
  }
  &:nth-child(4n) {
    margin-right: 0;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  &-inner {
    background-color: #fff;
    padding: 50px 30px 30px;
    border-radius: 5px;
    position: relative;
    max-width: 90%;
    .close-button {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 1.25rem;
      img {
        display: block;
        width: 18px;
      }
    }
  }
  &-body {
    .popup-message {
      h4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 500;
        text-align: center;
        img {
          display: block;
          width: 80px;
          margin-bottom: 20px;
        }
      }
    }
    .payment-methods {
      width: 420px;
      max-width: 100%;
      h4 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 30px;
        text-transform: capitalize;
      }
      &-links {
        button {
          display: block;
          width: 100%;
          padding: 12px;
          font-size: 1rem;
          border: 1px solid $color-light;
          border-radius: 5px;
          text-transform: capitalize;
          transition: all .3s ease;
          &:hover {
            background-color: $color-dark;
            color: $color-white;
          }
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .payment-form {
      width: 800px;
      max-width: 100%;
      h4 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 50px;
        text-transform: capitalize;
        color: $color-primary;
      }
      &-inner {
        flex-wrap: wrap;
        label {
          display: block;
          width: calc(50% - 10px);
          margin-right: 20px;
          margin-bottom: 20px;
          span {
            display: block;
            font-size: 0.85rem;
            font-weight: 500;
            margin-bottom: 10px;
            text-transform: capitalize;
            &.error {
              color: $color-red;
              margin-top: 10px;
              text-transform: capitalize;
            }
          }
          input {
            width: 100%;
            height: 48px;
            border: 1px solid $color-primary;
            padding: 0 20px;
            border-radius: 5px;
          }
          textarea {
            width: 100%;
            height: 180px;
            border: 1px solid $color-primary;
            padding: 10px;
            resize: none;
            border-radius: 5px;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
      .popup-buttons {
        justify-content: flex-end;
      }
    }
    .payment-success {
      width: 420px;
      max-width: 100%;
      h4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 500;
        text-align: center;
        img {
          display: block;
          width: 80px;
          margin-bottom: 20px;
        }
      }
    }
    .popup-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      button {
        width: 160px;
        max-width: 100%;
        padding: 10px;
        height: 48px;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 5px;
        background-color: $color-red;
        color: $color-white;
        text-transform: capitalize;
        &.success-btn {
          background-color: $color-green;
        }
        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
    &.popup-body-accessories {
      max-height: 75vh;
      overflow: auto;
      .popup-accessories-products {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

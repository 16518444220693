.title {
  margin: 0 0 40px;
  h2 {
    font-size: 2.25rem;
    line-height: 3.2rem;
    color: $color-white;
    font-weight: 700;
    padding-left: 35px;
    position: relative;
    text-transform: capitalize;
    &:before {
      content: '';
      position: absolute;
      width: 25px;
      height: 2px;
      background-color: $color-white;
      left: 0;
      top: 50%;
      margin-top: -1px;
    }
  }
}

.text-page {
  & > .wrapper {
    padding: 80px 20px;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1199px) {
  .product-item {
    width: calc(33.3% - 8px);
    &:nth-child(4n) {
      margin-right: 12px;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
@media (max-width: 991px) {
  .product-item {
    width: calc(50% - 6px);
    &:nth-child(3n) {
      margin-right: 12px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
@media (max-width: 580px) {
  .product-item {
    margin-bottom: 20px !important;
    .product-image {
      padding: 20px 12px;
      height: 220px;
      .cart-button {
        span {
          font-size: 12px;
          text-align: left;
        }
        img {
          width: 16px;
        }
      }
    }
    & > .p-20 {
      padding: 10px !important;
    }
    & > .flex {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    .product-info {
      display: flex;
      justify-content: space-between;
    }
    .product-prices {
      width: 100%;
      text-align: left;
    }
  }
}
@media (max-width: 420px) {
  .product-item {
    width: 100%;
    margin-right: 0;
    .product-image {
      height: 245px;
    }
    & > .flex {
      flex-direction: row;
      & > div {
        width: auto;
      }
    }
    .product-info {
      display: block;
    }
    .product-prices {
      text-align: right;
    }
  }
}

.breadcrumbs-wrapper {
    .breadcrumbs {
        display: flex;
        // align-items: center;
        // justify-content: center;
        text-transform: capitalize;
      
        &.product-page {
            margin-bottom: 40px;
        }

        li {
          a {
            font-size: 1rem;
            font-weight: 500;
            color: $color-white;
            transition: all 0.3s ease;
            &:hover {
              color: $color-wood;
            }
          }
          span {
            font-size: 1rem;
            font-weight: 400;
          }
          &:not(:last-of-type) {
            padding-right: 10px;
            &:after {
              content: '/';
              margin-left: 10px;
            }
          }
        }
      }
}
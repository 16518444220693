@media (max-width: 991px) {
  .product {
    & > .wrapper {
      padding: 30px 15px;
    }
    &-content {
      flex-direction: column;
      .product-gallery {
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
        .slick-slide {
          padding: 0;
        }
      }
      .product-info {
        width: 100%;
      }
    }
  }
}
@media (max-width: 520px) {
  .product {
    &-content {
      .product-info {
        .flex {
          flex-direction: column;
        }
        .count {
          margin-bottom: 30px;
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .about {
    &-slider {
      padding: 60px 0 0;
    }
  }
}
@media (max-width: 991px) {
  .about {
    & > .wrapper {
      padding: 40px 15px 60px;
    }
  }
}
@media (max-width: 480px) {
  .about {
    &-slider {
      .slick-slider {
        .slick-arrow {
          width: 48px;
          height: 48px;
          &:before {
            padding: 3px;
            margin-top: -4px;
            margin-left: -4px;
          }
          &.slick-prev {
            left: 12px;
          }
          &.slick-next {
            right: 12px;
          }
        }
      }
    }
  }
}
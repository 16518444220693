@media (max-width: 991px) {
  .contact {
    & > .wrapper {
      padding: 40px 15px 40px;
    }
    &-content {
      display: block;
    }
    &-info {
      width: 100%;
      margin-bottom: 40px;
      ul {
        display: flex;
        justify-content: space-between;
        li {
          width: auto;
          .icon {
            width: 36px;
            height: 36px;
            margin-right: 12px;
            svg {
              font-size: .85rem;
            }
          }
          &:not(:last-of-type) {
            margin-bottom: 0;
          }
        }
      }
    }
    &-image {
      width: 100%;
    }
    &-form {
      padding: 30px;
    }
    .map {
      height: 440px;
    }
  }
}
@media (max-width: 620px) {
  .contact {
    &-info {
      ul {
        display: block;
        li {
          width: 100%;
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
      }
    }
    &-form {
      padding: 30px 20px;
      & > div.flex {
        display: block;
        label {
          width: 100%;
        }
      }
    }
  }
}
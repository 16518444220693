@media (max-width: 991px) {
  .cart {
    &-content {
      .cart-wrap {
        overflow: auto;
      }
      .cart-table {
        min-width: 1000px;
        tbody {
          tr {
            &.tr-total {
              td {
                .flex {
                  flex-direction: column;
                  align-items: flex-start;
                  margin-bottom: 12px;
                  & > div {
                    margin-bottom: 15px;
                  }
                }
                i {
                  display: block;
                  max-width: 90vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .cart {
    &-content {
      .cart-table {
        max-width: 800px;
        tbody {
          tr {
            td {
              &:first-child {
                width: 160px !important;
              }
              &:last-child {
                button {
                  font-size: 16px;
                }
              }
            }
            &.tr-total {
              td {
                .flex {
                  flex-direction: column;
                  align-items: flex-start;
                  & > div {
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

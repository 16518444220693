 .banner {
   background-image: url('../../../assets/images/banner-image.jpg');
   background-repeat: no-repeat;
   background-position: center center;
  background-size: cover;
  position: relative;
  //background-color: $color-wood;
  &-content {
    padding: 240px 0 80px;
    color: $color-white;
    text-align: center;
    position: relative;
    z-index: 2;
  }
  &-title {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 500;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    li {
      a {
        font-size: 1rem;
        font-weight: 500;
        color: $color-white;
        transition: all 0.3s ease;
        &:hover {
          color: $color-wood;
        }
      }
      span {
        font-size: 1rem;
        font-weight: 400;
      }
      &:not(:last-of-type) {
        padding-right: 10px;
        &:after {
          content: '/';
          margin-left: 10px;
        }
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    opacity: 0.75;
  }
}

 @media (max-width: 500px) {
   .banner {
     background-image: url('../../../assets/images/banner-image-mobile.jpg');
   }
 }
